import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/PrivateRoute";

import { graphql } from "gatsby";

import Diary from "./app/diary";
import Feedback from "./app/feedback";
import Resources from "./app/resources";
import Guide from "./app/guide";

import { routes } from "../data/routes";

const App = () => (
	<>
		 <Router basepath="/:lang/app">
            <PrivateRoute path={'/diary'} component={Diary} />
            <PrivateRoute path={routes.feedback.replace('/app', '')} component={Feedback} />
            <PrivateRoute path={routes.resources.replace('/app', '')} component={Resources} />
            <PrivateRoute path={routes.guide.replace('/app', '')} component={Guide} />
        </Router>
	</>
);

export default App;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;