import React, { useContext } from "react";
import { navigate } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import { authContext } from "../contexts/authContext";
import { routes } from "../data/routes";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	const auth = useContext(authContext);
	const { language } = useI18next(); 

	if (!auth.isLoggedIn && location.pathname !== routes.login && !auth.isLoading) {
		navigate(`${routes.login}`);
		return null;
	}

	return <Component {...rest} location={location} />;
};

PrivateRoute.propTypes = {
	component: PropTypes.any.isRequired,
};

export default PrivateRoute;
